import React, {useEffect, useRef, useState} from 'react'
import {Link, navigate} from "gatsby";
import api from "../../api/credentials";
import {Snackbar} from "@material-ui/core";
import MuiAlert from "@mui/material/Alert";
import {Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel} from "react-accessible-accordion";
import {format} from "date-fns";
import {es} from "date-fns/locale";
import axios from "axios";

const Alert = React.forwardRef(function Alert(props, ref) {
    return (
        <MuiAlert
            elevation={6}
            ref={ref}
            variant="filled"
            {...props}
        />
    );
});

const BookingContent = ({order}) => {
    let selectHourText = "Seleccione una opción";
    const textInput = useRef(null);
    const [error, setError] = useState(null);
    const [creatingBooking, setCreatingBooking] = useState(false);

    const formatter = new Intl.NumberFormat("es-ES", {
        style: "currency",
        currency: "EUR",
    });
    const [newBooking, setNewBooking] = useState({
        facility_id: '',
        order_id: order.id,
        serviceDate: '',
        serviceHour: "",
        options: [],
    });

    const [success, setSuccess] = useState(null);

    useEffect(() => {
        if (newBooking.serviceDate != null && newBooking.facility_id) {
            axios
                .get(`${process.env.API_URL}/getAvailableDatesBooking`, {
                    params: {
                        facility_id: newBooking.facility_id,
                        service_id: newBooking.service_id,
                        units: newBooking.service_id,
                        serviceDate: newBooking.serviceDate,
                    },
                })
                .then((response) => {
                    const requestOptions = [];
                    for (const val of response.data.intervals) {
                        const option = {value: val, label: val};
                        requestOptions.push(option);
                    }
                    if (response.data.intervals.length == 0) {
                        selectHourText = "No hay horarios disponibles";
                    } else {
                        selectHourText = "Seleccione una opción";
                    }
                    setNewBooking((newBooking) => ({...newBooking, options: requestOptions}));
                    textInput.current?.focus();
                })
                .catch((error) => {
                    setNewBooking((newBooking) => ({...newBooking, serviceDate: ''}));
                    setError(error);
                });
        }
    }, [newBooking.serviceDate]);
    const handleClose = () => {
        setError(null);
    };
    const handleChange = (event) => {
        setError(null);

        const newObject = Object.assign({}, newBooking);
        switch (event.target.name) {

            case "serviceDate":
                Object.assign(newObject, {serviceHour: "", options: []});
                break;
            case "serviceHour":
                break;

            case 'facility_id':
                Object.assign(newObject, {serviceHour: "", serviceDate: '', options: []});
                break;
        }


        Object.assign(newObject, {[event.target.name]: event.target.value});


        setNewBooking(newObject);
    };






    const createBooking = () => {
        setError(null);
        setCreatingBooking(true);
        if (newBooking.facility_id === "" || newBooking.facility_id === null) {
            setError("Debe seleccionar una instalación");
            setCreatingBooking(false);

            return false;
        }
        if (newBooking.serviceDate === "" || newBooking.serviceDate === null) {
            setError("Debe seleccionar fecha para la reserva");
            setCreatingBooking(false);

            return false;
        }
        if (newBooking.serviceHour === "" || newBooking.serviceHour === null) {
            setError("Debe seleccionar hora para la reserva");
            setCreatingBooking(false);

            return false;
        }
        if (window.confirm("¿Seguro que quieres tramitar la devolución?")) {
            

            api(`${process.env.BACKEND_URL}/sanctum/csrf-cookie`).then(() => {
                api.post(`${process.env.API_URL}/booking`, {booking: newBooking})
                    .then((response) => {
                       
                        setSuccess("Reserva creada correctamente");
                        window.location.href = "/order-details?id=" + order.id;
                    })
                    .catch((error) => {
                        setCreatingBooking(false);

                        switch (error.response.status) {
                            case 401: // Not logged in
                            case 419: // Session expired
                                localStorage.removeItem("user");
                                window.dispatchEvent(new Event("removeUser"));
                                navigate("/signin");
                                break;

                            default:
                                setError(error.response.data.error);
                        }
                    });
            });
        }




    };
    return (
        <section className="order-area services-details-desc">
            <div className="container">
                <div className="mb-5 row">
                    <div className="col-12">
                        {order.items.map((item) => (
                            <>
                                <div key={item.id} className="row">
                                    <div className="mt-3 col-md-9 col-12">
                                        <div className="text-start">
                                            <div>
                                                Nº de pedido: {order.locator} <br />
                                                <Link to={`/service-details?slug=${item.service.slug}`}>{item.service.name}</Link>

                                                {item.uses > 0 && (
                                                    <>
                                                        <br />
                                                        <small className="text-muted">Usos restantes: {item.uses}</small>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mt-3 col-md-3 col-12 qrcode">
                                        {item.status === "completed" && (
                                            <>
                                                <img
                                                    src={`${process.env.BACKEND_URL}/qrcodes/${item.locator}.png`}
                                                />
                                            </>
                                        )}
                                    </div>
                                    <hr />
                                    <div className="services-details-info">
                                        <ul className="info">
                                            {!!(item.service.with_use_booking) && (
                                                <>
                                                    {!newBooking.facility_id || newBooking.facility_id ? (
                                                        <li key="installation">
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <span>Seleccionar instalación</span>
                                                            </div>
                                                            <div className="d-flex justify-content-between align-items-center">
                                                                <select
                                                                    className="form-select w-100"
                                                                    name="facility_id"
                                                                    onChange={handleChange}
                                                                >
                                                                    <option value="">{selectHourText}</option>
                                                                    {item.service.facility.facilities.map((item) => (
                                                                        <option
                                                                            key={'order-' + item.id + 'facility' + item.id}
                                                                            value={item.id}
                                                                        >
                                                                            {item.name}
                                                                        </option>
                                                                    ))}
                                                                </select>
                                                            </div>
                                                        </li>
                                                    ) : null}
                                                    {newBooking.facility_id != '' ? (
                                                        <>
                                                            <li className="date" key="date">
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <span>Fecha</span>
                                                                </div>
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <input
                                                                        type="date"
                                                                        className="form-control w-100 float-end"
                                                                        name="serviceDate"
                                                                        min={format(new Date(), "yyyy-MM-dd")}
                                                                        value={newBooking.serviceDate}
                                                                        onChange={handleChange}
                                                                    />
                                                                </div>

                                                            </li>
                                                            {newBooking.serviceDate ? (
                                                                <li className="date" key="hour">
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <span>Hora</span>
                                                                    </div>
                                                                    <div className="d-flex justify-content-between align-items-center">
                                                                        <select
                                                                            className="form-control w-100"
                                                                            name="serviceHour"
                                                                            ref={textInput}
                                                                            onChange={handleChange}
                                                                        >
                                                                            <option value="">{selectHourText}</option>
                                                                            {newBooking.options.map((item) => (
                                                                                <option
                                                                                    key={'order-' + item.id + 'hour' + item.value}
                                                                                    value={item.value}
                                                                                >
                                                                                    {item.label}
                                                                                </option>
                                                                            ))}
                                                                        </select>
                                                                    </div>

                                                                </li>
                                                            ) : null}
                                                        </>
                                                    ) : null}

                                                </>
                                            )}






                                        </ul>
                                        <div className="row">

                                            <div className="col-md-6 col-12">
                                                <div className="btn-box">

                                                    <a href={`/order-details?id=${item.id}`} className="btn-refund-order w-100">
                                                        Cancelar
                                                    </a>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-12">
                                                <div className="btn-box">
                                                    <a
                                                        className="default-btn"
                                                        role="button"
                                                        onClick={createBooking}
                                                        disabled={creatingBooking ? "disabled" : ""}
                                                    >
                                                        {creatingBooking ? (
                                                            <div
                                                                className="spinner-border spinner-border-sm pr-5"
                                                                role="status"
                                                            >
                                                                <span className="visually-hidden">Loading...</span>
                                                            </div>
                                                        ) : (
                                                            <i className="flaticon-agendas"></i>
                                                        )}
                                                        Reservar<span></span>
                                                    </a>
                                                </div>

                                            </div>
                                        </div>




                                    </div>
                                </div>






                            </>
                        ))}
                    </div>
                </div>
            </div>
            <Snackbar
                open={error !== null}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity="error"
                    sx={{width: "100%"}}
                >
                    {error}
                </Alert>
            </Snackbar>
        </section>
    );
};

export default BookingContent;
